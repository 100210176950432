import { NGXLogger } from 'ngx-logger';
import { Component, OnInit } from '@angular/core';
import { LabelAmountService } from "../../../../service/label-amount.service";
import { Diabetes } from "../../../../constants/subject-criteria.constants";

@Component({
  selector: 'app-diet',
  templateUrl: './diet.component.html',
  styleUrls: ['./diet.component.scss']
})
export class DietComponent implements OnInit {
  public hasDiet = false;
  public hasDASH = false;
  public hasLowCarb = false;
  public hasMediterranean = true;
  public hasVegetarian = false;

  constructor(private logger: NGXLogger,
              private labelAmountService: LabelAmountService) { }

  ngOnInit(): void {
    this.init();
  }

  private init() {
    const medicalDiagnosis = this.labelAmountService.subjectCriteria.medicalDiagnosis;

    this.hasDASH = medicalDiagnosis.coronary || medicalDiagnosis.heartFailure || medicalDiagnosis.highCholesterol || medicalDiagnosis.hypertension || this.labelAmountService.isObese;

    this.hasLowCarb = medicalDiagnosis.coronary || medicalDiagnosis.highCholesterol;

//    this.hasMediterranean = medicalDiagnosis.coronary || medicalDiagnosis.diabetes === Diabetes.Type2 || this.labelAmountService.isObese;

    this.hasVegetarian = medicalDiagnosis.coronary || medicalDiagnosis.highCholesterol;

    this.hasDiet = this.hasDASH || this.hasLowCarb || this.hasMediterranean || this.hasVegetarian;
  }

}
