<div class="dietary-section w-100">
  <div>
    Diet recommendations for&nbsp;<span class="emphasis color-primary">your</span>&nbsp;health:
  </div>
  <div *ngIf="hasDiet">
    <ul>
      <li *ngIf="hasMediterranean"><app-diet-mediterranean></app-diet-mediterranean></li>
      <li *ngIf="hasDASH"><app-diet-dash></app-diet-dash></li>
      <li *ngIf="hasLowCarb"><app-diet-low-carb></app-diet-low-carb></li>
      <li *ngIf="hasVegetarian"><app-diet-vegetarian></app-diet-vegetarian></li>
    </ul>
  </div>
  <div *ngIf="!hasDiet">
    <app-diet-mediterranean></app-diet-mediterranean>
  </div>
</div>
