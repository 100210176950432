import {ActivityLevel, Diabetes, KidneyDisease, Sex} from "../constants/subject-criteria.constants";

export class SubjectCriteria {
  public age: number | undefined;
  public heightFeet: number | undefined;
  public heightInches: number | undefined;
  public weight: number | undefined;
  public pregnant: boolean | undefined;
  public sex: Sex | undefined;
  public activityLevel: ActivityLevel | undefined;
  public medicalDiagnosis = new MedicalDiagnosis();
  public loseWeight = false;
}

export class MedicalDiagnosis {
  public kidney: KidneyDisease = KidneyDisease.None;
  public coronary = false;
  public diabetes: Diabetes = Diabetes.None;
  public heartFailure = false;
  public highCholesterol = false;
  public hyperlipidemia = false;
  public hypertension = false;
  public obesity = false;
}
