import { HttpClientModule } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router'
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppComponent } from './app.component';
import { AppHeadingComponent } from './component/app-heading/app-heading.component';
import { AppHeadingImgComponent } from './component/app-heading-img/app-heading-img.component';
import { DailyValueLabelComponent } from './component/daily-value-label/daily-value-label.component';
import { DietComponent } from './pages/result/component/diet/diet.component';
import { LabelComponent } from './component/label/label.component';
import { SectionHeadingComponent } from './component/section-heading/section-heading.component';
import { StepHeadingComponent } from './component/step-heading/step-heading.component';

import { DisclaimerComponent } from './pages/result/component/disclaimer/disclaimer.component';
import { InformationComponent } from './pages/result/component/information/information.component';
import { ResultComponent } from './pages/result/result.component';
import { SubjectCriteriaComponent } from './pages/subject-criteria/subject-criteria.component';
import { SubjectAgeValidator } from './pages/subject-criteria/directive/subject-age-validator.directive';
import { SubjectHeightFeetValidator } from "./pages/subject-criteria/directive/subject-height-feet-validator.directive";
import { SubjectHeightInchValidator } from "./pages/subject-criteria/directive/subject-height-inch-validator.directive";
import { SubjectWeightValidator } from "./pages/subject-criteria/directive/subject-weight-validator.directive";
import { CriteriaFormComponent } from './pages/subject-criteria/component/criteria-form/criteria-form.component';
import { FemaleSexModalComponent } from './pages/subject-criteria/component/female-sex-modal/female-sex-modal.component';

import { AppRoutingModule } from './routing/app-routing.module';
import { AppResolver } from "./routing/app.resolver";
import { DietMediterraneanComponent } from './pages/result/component/diet/diet-mediterranean/diet-mediterranean.component';
import { DietDashComponent } from './pages/result/component/diet/diet-dash/diet-dash.component';
import { DietLowCarbComponent } from './pages/result/component/diet/diet-low-carb/diet-low-carb.component';
import { DietVegetarianComponent } from './pages/result/component/diet/diet-vegetarian/diet-vegetarian.component';
import { NutritionLabelComponent } from './component/nutrition-label/nutrition-label.component';
import { SugarWarningComponent } from './pages/result/component/sugar-warning/sugar-warning.component';
import { LabelItemLayoutComponent } from './component/label-item-layout/label-item-layout.component';
import { LabelItemAmountComponent } from './component/label-item-amount/label-item-amount.component';

@NgModule({
  declarations: [
    AppComponent,
    AppHeadingComponent,
    AppHeadingImgComponent,
    CriteriaFormComponent,
    DailyValueLabelComponent,
    DietComponent,
    DisclaimerComponent,
    FemaleSexModalComponent,
    InformationComponent,
    LabelComponent,
    ResultComponent,
    SectionHeadingComponent,
    StepHeadingComponent,
    SubjectAgeValidator,
    SubjectHeightFeetValidator,
    SubjectHeightInchValidator,
    SubjectWeightValidator,
    SubjectCriteriaComponent,
    DietMediterraneanComponent,
    DietDashComponent,
    DietLowCarbComponent,
    DietVegetarianComponent,
    NutritionLabelComponent,
    SugarWarningComponent,
    LabelItemLayoutComponent,
    LabelItemAmountComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    LoggerModule.forRoot({ level: NgxLoggerLevel.DEBUG }),
    NgbModule,
    ReactiveFormsModule,
    RouterModule
  ],
  providers: [
    AppResolver
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
