<div class="printHeader">
  <h1>www.YourBetterHealth.org</h1>
</div>
<app-heading></app-heading>
<!--
  <div class="ml-auto align-self-center">
    <fa-icon [icon]="['fas', 'save']" class="text-primary" size="lg" (click)="save()"></fa-icon>
  </div>
  -->

<app-section-heading>Steps to Choosing Healthy Food</app-section-heading>

<app-step-heading>Step 1. Use <span class="emphasis">your</span> Nutrition Facts label to help you choose the balance of the nutrients that <span class="emphasis">your</span> body needs.</app-step-heading>

<div class="row justify-content-space-between p-4">
  <div class="col-md px-0">
    <app-nutrition-label></app-nutrition-label>
  </div>
  <div class="col-md px-0">
    <app-disclaimer></app-disclaimer>
  </div>
</div>

<app-step-heading>Step 2. Use Daily Value % (DV%) for a quick reference of nutrient amounts that <span class="emphasis">your</span> body needs.</app-step-heading>

<div class="d-flex mx-auto p-5 w-100 justify-content-center" >
  <app-daily-value-label [isAnnotated]="true"></app-daily-value-label>
</div>

<app-step-heading>Step 3. Don’t let the claims on the package fool you. <strong>Read the list of ingredients.</strong> If it’s a long list and contains many items you don’t recognize, the food is highly processed and not healthy.</app-step-heading>

<div class="row p-4 no-print">
  <div class="col-3">
    <div class="warning-claim mx-3">
      <span>Don't let the claims on the package fool you</span>
    </div>
    <div class="info-claim mx-3 mt-4">
      <span>This snack is not balanced and contains many unknown ingredients</span>
    </div>
  </div>
  <div class="col">
    <div class="d-flex mx-auto w-100 justify-content-center" >
      <img src="./assets/img/annotated-BB-label.png" style="width: 22rem" alt="Example Nutrition Label">
    </div>
  </div>
</div>

<app-step-heading>Step 4. Learn more about diets that will improve <span class="emphasis">your</span> health.</app-step-heading>
<div class="row justify-content-md-center p-4 no-print">
  <div col-md class="mx-auto">
    <img src="./assets/img/family-dinner.jpg" style="width: 22rem" alt="Family dinner">
  </div>
  <div col-md class="mx-auto bg-light p-2">
    <app-diet></app-diet>
  </div>
</div>


<div class="d-flex flex-row px-5 py-3 w-100 bg-white no-print">
  <button class='btn btn-primary ml-auto' (click)="print()">Print My Label</button>
  <button class='btn btn-primary ml-4' (click)="back()">Go Back</button>
</div>

<div class="printFooter">
  <h4>©2021 Johanna Kasidi</h4>
</div>
