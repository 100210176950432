import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from "ngx-logger";
import { STORAGE_ID } from "../../../../constants/app.constants";
import { Diabetes, KidneyDisease } from "../../../../constants/subject-criteria.constants";
import { LabelAmountService } from "../../../../service/label-amount.service";
import { SubjectCriteria } from "../../../../model/subject-criteria.model";
import { FemaleSexModalComponent } from "../female-sex-modal/female-sex-modal.component";

@Component({
  selector: 'app-criteria-form',
  templateUrl: './criteria-form.component.html',
  styleUrls: ['./criteria-form.component.scss']
})
export class CriteriaFormComponent implements OnInit {

  public subjectCriteria = new SubjectCriteria();
  public formDisabled = false;

  constructor(private logger: NGXLogger,
              private modalService: NgbModal,
              private router: Router,
              private labelAmountService: LabelAmountService) { }

  ngOnInit(): void {
    if (!!this.labelAmountService.subjectCriteria) {
      this.subjectCriteria = this.labelAmountService.subjectCriteria;
    }
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  public next() {
    this.logger.debug('criteria: ' + JSON.stringify(this.subjectCriteria));
    sessionStorage.setItem(STORAGE_ID, JSON.stringify(this.subjectCriteria));
    this.labelAmountService.computeLabelAmounts(this.subjectCriteria);
    this.router.navigate(['/result']);
  }

  public onPregnantSelected(event: any) {
    this.logger.debug('onPregnantSelected(): ' + event.target.value);
    this.openModal();
  }

  public onKidneyDiseaseSelected(event: any) {
    this.logger.debug('onKidneyDiseaseSelected(): ' + event.target.value);
    const selection = event.target.value as KidneyDisease;
    if (this.subjectCriteria.medicalDiagnosis.kidney === selection) {
      this.subjectCriteria.medicalDiagnosis.kidney = KidneyDisease.None;
      event.target.checked = false;
    } else {
      this.subjectCriteria.medicalDiagnosis.kidney = selection;
    }
  }

  public onDiabetesSelected(event: any) {
    this.logger.debug('onDiabetesSelected(): ' + event.target.value);
    const selection = event.target.value as Diabetes;
    if (this.subjectCriteria.medicalDiagnosis.diabetes === selection){
      this.subjectCriteria.medicalDiagnosis.diabetes = Diabetes.None;
      event.target.checked = false;
    } else {
      this.subjectCriteria.medicalDiagnosis.diabetes = selection;
    }
  }

  private openModal() {
    const modalOptions: NgbModalOptions = {
      size: "md",
      centered: true,
      backdrop: 'static',
      animation: true,
      keyboard: false,
      backdropClass: "modal-backdrop"
    };

    this.modalService.open(FemaleSexModalComponent, modalOptions).result.then(result => {
      this.logger.debug('Modal result: ' + result);
      // if result true then disable all controls
      this.formDisabled = true;
    });

  }

}
