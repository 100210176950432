<div class="nut-label">
  <div class="title">
    <span class="emphasis">Your</span>&nbsp;Nutrition Facts
  </div>
  <hr class="thickest">
  <div class="subTitle">
    Amounts per&nbsp;<span class="emphasis">Day</span>
  </div>
  <hr class="medium">
  <div class="calories">
    <span class="itemName">Calories</span><span class="itemAmount">{{labelAmount.calories}}</span>
  </div>
  <hr class="thick">
  <div class="labelItem">
    <span class="mainItemName">Total Fat</span><span class="itemAmount">{{labelAmount.totalFat.low}}-{{labelAmount.totalFat.high}}g</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="subItemName">Saturated Fat</span><span class="itemAmount">{{labelAmount.saturatedFat}}g</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="subItemName">Trans Fat</span><span class="itemWarning" *ngIf="labelAmount.warnTransFat">Avoid Trans Fats</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="mainItemName">Cholesterol</span><span class="itemAmount">{{labelAmount.cholesterol}}mg</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="mainItemName">Sodium</span><span class="itemAmount">{{labelAmount.sodium}}mg</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="mainItemName">Total Carbohydrates</span>
    <span class="itemAmount">{{labelAmount.totalCarbohydrates.low}}-{{labelAmount.totalCarbohydrates.high}}g</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="subItemName">Daily Fiber</span><span class="itemAmount">{{labelAmount.dietaryFiber}}g</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="subItemName">Total Sugars</span><span class="itemAmount"></span>
  </div>
  <hr class="thin ml-4">
  <div class="labelItem">
    <span class="subChildItemName">Includes Added Sugars</span>
    <span class="itemWarning" *ngIf="labelAmount.warnAddedSugar">Avoid Added Sugars</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="mainItemName">Protein</span><span class="itemAmount">{{labelAmount.protein}}g</span>
  </div>
  <hr class="thickest">
  <div class="labelItem">
    <span class="itemName">Vitamin D</span><span class="itemAmount">{{labelAmount.ageBasedAmount.vitaminD}}</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="itemName">Calcium</span><span class="itemAmount">{{labelAmount.ageBasedAmount.calcium}}</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="itemName">Iron</span><span class="itemAmount">{{labelAmount.ageBasedAmount.iron}}</span>
  </div>
  <hr class="thin">
  <div class="labelItem">
    <span class="itemName">Potassium</span><span class="itemAmount">{{labelAmount.ageBasedAmount.potassium}}</span>
  </div>

</div>

<div *ngIf="showAnnotations">

</div>
