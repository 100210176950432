import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { LabelAmount } from 'src/app/model/label-amount.model';
import { LabelAmountService } from "../../service/label-amount.service";

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent implements OnInit {

  public labelAmount: LabelAmount;
  public showAnnotations = true; // TODO make input parameter

  constructor(private logger: NGXLogger,
              private labelAmountService: LabelAmountService) {
    this.labelAmount = this.labelAmountService.labelAmount;
  }

  ngOnInit(): void {
  }

}
