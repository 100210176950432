import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[subjectWeightValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SubjectWeightValidator,
      multi: true
    }
  ]
})
export class SubjectWeightValidator implements Validator {

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return (this.validateWeight(control.value)? null : {invalid: control.value});
  }

  private validateWeight(value: number) {
    return !!value && value > 0 && value <= 999;
  }

}
