import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { MedicalDiagnosis, SubjectCriteria } from "../model/subject-criteria.model";
import {
  ActivityLevel,
  ActivityLevelFactor,
  Diabetes,
  KidneyDisease,
  MinimumCalories,
  Sex,
  VCIP_Female,
  VCIP_Male
} from "../constants/subject-criteria.constants";
import { AgeBasedAmount, AmountRange, LabelAmount } from "../model/label-amount.model";

@Injectable({
  providedIn: 'root'
})
export class LabelAmountService {

  private _subjectCriteria: SubjectCriteria | undefined ;
  private _labelAmount = new LabelAmount();
  private _bodyMassIndex = 0;
  private _isObese = false;

  constructor(private logger: NGXLogger) {
  }

  public get subjectCriteria(): SubjectCriteria {
    return <SubjectCriteria>this._subjectCriteria;
  }

  public get labelAmount(): LabelAmount {
    return this._labelAmount;
  }

  public get isObese(): boolean {
    return this._isObese;
  }

  public computeLabelAmounts(subjectCriteria: SubjectCriteria): void {
    this._subjectCriteria = subjectCriteria;
    const age = subjectCriteria.age || 0;
    const sex = subjectCriteria.sex || Sex.Unspecified;
    const weight = subjectCriteria.weight || 0;
    const height = this.computeOverallHeight(subjectCriteria);
    const activityLevel = subjectCriteria.activityLevel || ActivityLevel.Unspecified;
    this._bodyMassIndex = this.computeBodyMassIndex(height, weight);
    this._isObese = this._subjectCriteria.medicalDiagnosis.obesity || this._bodyMassIndex >= 30;
    const metabolicRate = this.computeMetabolicRate(sex, age, height, weight);
    const calories = this.computeCalories(sex, metabolicRate, activityLevel, subjectCriteria.loseWeight);
    this._labelAmount.totalFat = this.computeTotalFatRange(calories, subjectCriteria.medicalDiagnosis);
    this._labelAmount.saturatedFat = this.computeSaturatedFat(calories, subjectCriteria.medicalDiagnosis);
    this._labelAmount.totalCarbohydrates = this.computeTotalCarbohydrates(calories);
    this._labelAmount.sodium = this.determineSodium(subjectCriteria.medicalDiagnosis);
    this._labelAmount.cholesterol = this.determineCholesterol(subjectCriteria.medicalDiagnosis);
    this._labelAmount.dietaryFiber = this.computeDietaryFiber(calories, subjectCriteria.medicalDiagnosis);
    this._labelAmount.addedSugar = this.computeAddedSugar(calories);
    this._labelAmount.protein = this.computeProtein(weight, subjectCriteria.medicalDiagnosis);
    this._labelAmount.warnTransFat = this.determineTransFatWarning(subjectCriteria.medicalDiagnosis);
    this._labelAmount.warnAddedSugar = this.determineAddedSugarWarning(subjectCriteria.medicalDiagnosis);
    this.logger.info('warn added sugar: ' + this._labelAmount.warnAddedSugar);
    this._labelAmount.warnSweetDrinks = this.determineSweetDrinksWarning(subjectCriteria.medicalDiagnosis);
    this.determineAgeBasedAmounts(sex, age, subjectCriteria.medicalDiagnosis);
    this._labelAmount.calories = calories;
  }

  public computeBodyMassIndex(height: number, weight: number): number {
    this.logger.debug('weight (lbs): ' + weight + '  height (inch): ' + height);
    const bodyMassIndex = Math.round(703 * (weight / (height * height)));
    this.logger.debug('BMI: ' + bodyMassIndex);
    return bodyMassIndex;
  }

  private computeMetabolicRate(sex: Sex, age: number, height: number, weight: number) {
    let metabolicRate = 0;
    if (sex === Sex.Female) {
      metabolicRate = 655 + 4.3 * weight + 4.7 * height - 4.7 * age;
    } else if (sex === Sex.Male) {
      metabolicRate = 66 + 6.3 * weight + 12.9 * height - 6.8 * age;
    }
    this.logger.debug('sex: ' + sex + '  BMR: ' + metabolicRate);
    return metabolicRate;
  }

  private computeCalories(sex: Sex, metabolicRate: number, activityLevel: ActivityLevel, loseWeight?: boolean) {

    // @ts-ignore
    let calories = Math.round(metabolicRate * ActivityLevelFactor.get(this._subjectCriteria.activityLevel));
    this.logger.debug('initial calories: ' + calories + ' MR: ' + metabolicRate + '  ALF: ' +
      ActivityLevelFactor.get(activityLevel))

    if (loseWeight) {
      calories = calories - 500;
    }

    const minimumCalories = MinimumCalories.get(sex);
    // @ts-ignore
    calories = (calories > minimumCalories) ? calories: minimumCalories;

    return calories;
  }

  private computeTotalFatRange(calories: number, medicalDiagnosis: MedicalDiagnosis): AmountRange {
    const fatRange =
      new AmountRange(Math.round((calories * .2)/9.0), Math.round((calories * .35)/9.0), 'g');
    if (medicalDiagnosis.kidney !== KidneyDisease.None) {
      fatRange.low = Math.round((calories * .3)/9.0);
      fatRange.high = Math.round((calories * .4)/9.0);
    }
    fatRange.suffix = 'g';
    return fatRange;
  }

  private computeSaturatedFat(calories: number, medicalDiagnosis: MedicalDiagnosis) {
    let satFat = Math.round((calories * .1)/9.0);
    if (medicalDiagnosis.coronary ||
      medicalDiagnosis.hyperlipidemia ||
      medicalDiagnosis.diabetes === Diabetes.Type2 ||
      this._isObese) {
      satFat = Math.round((calories * .07)/9.0);
    }
    return satFat;
  }

  private determineCholesterol(medicalDiagnosis: MedicalDiagnosis): number {
    let chol = 300;
    if (medicalDiagnosis.coronary ||
      medicalDiagnosis.highCholesterol ||
      medicalDiagnosis.hyperlipidemia ||
      medicalDiagnosis.diabetes === Diabetes.Type2 || this._isObese) {
      chol = 200;
    }
    return chol;
  }

  private determineSodium(medicalDiagnosis: MedicalDiagnosis): number {
    let sodium = 2300;
    if (medicalDiagnosis.kidney !== KidneyDisease.None) {
      sodium = 2000;
    } else if (medicalDiagnosis.coronary ||
      medicalDiagnosis.heartFailure ||
      medicalDiagnosis.hypertension || this._isObese) {
      sodium = 1500
    }
    return sodium;
  }

  private computeTotalCarbohydrates(calories: number): AmountRange {
    const totalCarbohydratesRange =
      new AmountRange(Math.round((calories * 0.45)/4.0), Math.round((calories * 0.65)/4.0), 'g');
    return totalCarbohydratesRange;
  }

  private computeDietaryFiber(calories: number, medicalDiagnosis: MedicalDiagnosis): AmountRange {
    const d = Math.round(calories * 0.014);
    const df = new AmountRange(d, d, 'g');
    if (medicalDiagnosis.kidney !== KidneyDisease.None) {
      df.low = 20;
      df.high = 25;
    }
    return df;
  }

  private computeAddedSugar(calories: number): number {
    return Math.round((calories * 0.1)/4.0);
  }

  private computeProtein(weight: number, medicalDiagnosis: MedicalDiagnosis): AmountRange {
    const p = Math.round(weight * 0.36);
    const protein = new AmountRange(p, p, 'g');
    if (medicalDiagnosis.kidney === KidneyDisease.Regular) {
      protein.low = Math.round(weight * 0.45359237 * 0.8);
      protein.high = Math.round(weight * 0.45359237);
    } else if (medicalDiagnosis.kidney === KidneyDisease.Hemodialysis) {
      protein.low = Math.round(weight * 0.45359237 * 1.2);
      protein.high = Math.round(weight * 0.45359237 * 1.3);
    }
    return protein;
  }

  private determineAgeBasedAmounts(sex: Sex, age: number, medicalDiagnosis: MedicalDiagnosis) {
    let match: AgeBasedAmount | undefined;
    if (sex === Sex.Female) {
      match = VCIP_Female.find(vcip => (age >= vcip.minAge && age <= vcip.maxAge));
    } else if (sex === Sex.Male) {
      match = VCIP_Male.find(vcip => (age >= vcip.minAge && age <= vcip.maxAge));
    }
    if (!! match) {
      this._labelAmount.ageBasedAmount = JSON.parse(JSON.stringify(match));
      if (medicalDiagnosis.kidney !== KidneyDisease.None) {
        this._labelAmount.ageBasedAmount.potassium = '2000 mg';
        this._labelAmount.ageBasedAmount.calcium.low = 1400;
        this._labelAmount.ageBasedAmount.calcium.high = 1600;
      }
    }
  }

  private determineTransFatWarning(medicalDiagnosis: MedicalDiagnosis): boolean {
    return this._bodyMassIndex > 30 ||
      medicalDiagnosis.coronary ||
      medicalDiagnosis.hyperlipidemia ||
      medicalDiagnosis.diabetes === Diabetes.Type2 ||
      medicalDiagnosis.highCholesterol ||
      this._isObese;
  }

  private determineAddedSugarWarning(medicalDiagnosis: MedicalDiagnosis): boolean {
    return medicalDiagnosis.coronary ||
      medicalDiagnosis.hyperlipidemia ||
      medicalDiagnosis.diabetes === Diabetes.Type2 ||
      medicalDiagnosis.highCholesterol ||
      this._isObese;
  }

  private determineSweetDrinksWarning(medicalDiagnosis: MedicalDiagnosis): boolean {
    return this._bodyMassIndex > 30 ||
      medicalDiagnosis.diabetes === Diabetes.Type2 ||
      this._isObese;
  }
  private computeOverallHeight(subjectCriteria: SubjectCriteria): number {
    let oh = 0;
    if (!!subjectCriteria.heightFeet && !!subjectCriteria.heightInches) {
      oh = subjectCriteria.heightFeet * 12 + subjectCriteria.heightInches;
    }
    return oh;
  }

}
