<app-heading-img></app-heading-img>
<div class="d-flex row justify-content-center my-3">
  <div class="col-md info-section m-3 py-3">
    <span>Did you know that the values on Nutrition Facts labels found on foods are for people who require 2000 calories a day to maintain their weight?</span>
    <div class="d-flex mx-auto pt-3 w-100 justify-content-center" >
      <app-daily-value-label class="w-100"></app-daily-value-label>
    </div>
  </div>
  <div class="col-md info-section m-3 py-3">
    <span>Create <span class="emphasis color-primary">your</span> Nutrition Facts Label with values specific to your calorie and health needs.</span>
    <img src="/assets/img/shopper.jpg" class="mx-auto  pt-3 w-100" alt="Shop wisely">
  </div>
</div>

<app-step-heading class="text-center">Take the survey below to create <span class="emphasis">your</span> Nutrition Facts label for <span class="emphasis">your</span> specific needs</app-step-heading>
<app-criteria-form></app-criteria-form>
