import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-label-item-layout',
  templateUrl: './label-item-layout.component.html',
  styleUrls: ['./label-item-layout.component.scss']
})
export class LabelItemLayoutComponent implements OnInit {

  @Input()
  isAnnotated = false;

  @Input()
  annotation = '';

  @Input()
  additionalMainItemClasses: string[] = [];

  @Input()
  additionalItemContentClasses: string[] = [];

  @Input()
  additionalAnnotationClasses: string[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
