import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diet-mediterranean',
  templateUrl: './diet-mediterranean.component.html',
  styleUrls: ['./diet-mediterranean.component.scss']
})
export class DietMediterraneanComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
