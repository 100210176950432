import { AgeBasedAmount, AmountRange } from "../model/label-amount.model";

export class SubjectCriteriaConstants {

}

export enum Sex {
  Unspecified = 'unspecified',
  Male = 'male',
  Female = 'female'
}
export enum ActivityLevel {
  Unspecified = 'unspecified',
  None = 'none',
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Extra = 'extra'
}

export enum KidneyDisease {
  None = 'none',
  Regular = 'regular',
  Hemodialysis = 'dialysis'
}

export enum Diabetes {
  None = 'none',
  Type1 = 'type1',
  Type2 = 'type2'
}

export const ActivityLevelFactor: Map<ActivityLevel, number> = new Map([
  [ActivityLevel.Unspecified, 0],
  [ActivityLevel.None, 1.2],
  [ActivityLevel.Low, 1.375],
  [ActivityLevel.Medium, 1.55],
  [ActivityLevel.High, 1.725],
  [ActivityLevel.Extra, 1.9]
  ]
);

export const MinimumCalories: Map<Sex, number> = new Map([
  [Sex.Unspecified, 0],
  [Sex.Female, 1200],
  [Sex.Male, 1800]
  ]
);

export const VCIP_Female: AgeBasedAmount[] = [
  {
    minAge: 19,
    maxAge: 50,
    vitaminD: '15 mcg',
    calcium: new AmountRange(1000, 1000, 'mg'),
    iron: '18 mg',
    potassium: '2600 mg'
  },
  {
    minAge: 51,
    maxAge: 70,
    vitaminD: '15 mcg',
    calcium: new AmountRange(1200, 1200, 'mg'),
    iron: '8 mg',
    potassium: '2600 mg'
  },
  {
    minAge: 71,
    maxAge: 111,
    vitaminD: '20 mcg',
    calcium: new AmountRange(1200, 1200, 'mg'),
    iron: '8 mg',
    potassium: '2600 mg'
  }
];

export const VCIP_Male: AgeBasedAmount[] = [
  {
    minAge: 19,
    maxAge: 70,
    vitaminD: '15 mcg',
    calcium: new AmountRange(1000, 1000, 'mg'),
    iron: '8 mg',
    potassium: '3400 mg'
  },
  {
    minAge: 71,
    maxAge: 111,
    vitaminD: '20 mcg',
    calcium: new AmountRange(1200, 1200, 'mg'),
    iron: '8 mg',
    potassium: '3400 mg'
  }
];
