import { Component, Input, OnInit } from '@angular/core';
import { AmountRange } from "../../model/label-amount.model";

@Component({
  selector: 'app-label-item-amount',
  templateUrl: './label-item-amount.component.html',
  styleUrls: ['./label-item-amount.component.scss']
})
export class LabelItemAmountComponent implements OnInit {

  public showRange = true;
  public singleValue = 0;

  @Input("rangeValue") rangeValue = new AmountRange(0, 0, '');

  constructor() { }

  ngOnInit(): void {
    if (!this.rangeValue.low || !this.rangeValue.high || this.rangeValue.low === this.rangeValue.high) {
      this.showRange = false;
      if (!!this.rangeValue.low) {
        this.singleValue = this.rangeValue.low;
      } else {
        this.singleValue = this.rangeValue.high;
      }
    }
  }

}
