import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { STORAGE_ID } from "../constants/app.constants";
import { LabelAmountService } from "../service/label-amount.service";

@Injectable({
  providedIn: 'root'
})
export class AppResolver implements Resolve<boolean> {

  constructor(private labelAmountsService: LabelAmountService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (! this.labelAmountsService.subjectCriteria) {
      const criteria = sessionStorage.getItem(STORAGE_ID);
      if (!!criteria && criteria.length > 0) {
        this.labelAmountsService.computeLabelAmounts(JSON.parse(criteria));
      }
    }
    return of(true);
  }
}
