import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { LabelAmount } from "../../model/label-amount.model";
import { LabelAmountService } from "../../service/label-amount.service";

@Component({
  selector: 'app-nutrition-label',
  templateUrl: './nutrition-label.component.html',
  styleUrls: ['./nutrition-label.component.scss']
})
export class NutritionLabelComponent implements OnInit {

  public labelAmount: LabelAmount;

  constructor(private logger: NGXLogger,
              private labelAmountService: LabelAmountService) {
    this.labelAmount = this.labelAmountService.labelAmount;
  }

  ngOnInit(): void {
  }

}
