<fieldset [disabled]="formDisabled">
  <form (ngSubmit)="next()" #subjectForm="ngForm">
    <div class="container p-0">
      <div class="d-flex row justify-content-between">
        <div class="col-md"> <!-- First column -->
          <div class="criteria-section bg-light"> <!-- First section -->
            <!-- Age -->
            <div class="criteria-item">
              <label for="age" class="criteria-item-label">Age:</label>
              <input type="number" #age="ngModel" id="age" name="age" [(ngModel)]="subjectCriteria.age" subjectAgeValidator min="19" max="100">
              <div [hidden]="age.valid || age.pristine" class="alert alert-danger">
                Age must be between 19 and 100 years old
              </div>
            </div> <!-- Age -->

            <!-- Pregnancy -->
            <div class="criteria-item">
              <label class="criteria-item-label-long">Are you pregnant or breast feeding?</label>
              <div class="d-flex row ml-2">
                <div class="d-flex col-3">
                  <div class="d-flex row">
                    <label for="yes">
                      <input type="radio" class="mr-1" id="yes" name="pregnant" [value]="true" required
                             [(ngModel)]="subjectCriteria.pregnant" (click)="onPregnantSelected($event)">
                      Yes
                    </label>
                  </div>
                </div>
                <div class="d-flex col-3">
                  <div class="d-flex row">
                    <label for="no">
                      <input type="radio" class="mr-1" id="no" name="pregnant" [value]="false" required
                             [(ngModel)]="subjectCriteria.pregnant">
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <!-- Sex -->
            <div class="criteria-item">
              <label class="criteria-item-label-long">Biological Sex:</label>
              <div class="d-flex row ml-2">
                <div class="d-flex col-3">
                  <div class="d-flex row">
                    <label for="male">
                      <input type="radio" class="mr-1" #sex="ngModel" id="male" name="sex" value="male" required
                             [(ngModel)]="subjectCriteria.sex">
                      Male
                    </label>
                  </div>
                </div>
                <div class="d-flex col-3">
                  <div class="d-flex row">
                    <label for="female">
                      <input type="radio" class="mr-1" #sex="ngModel" id="female" name="sex" value="female" required
                             [(ngModel)]="subjectCriteria.sex">
                      Female
                    </label>
                    <div [hidden]="sex.valid || sex.pristine" class="alert alert-danger">
                      Biological Sex must be selected
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Height -->
            <div class="criteria-item">
              <label class="criteria-item-label">Height:</label>
              <input type="number" class="mr-2" #heightFeet="ngModel" id="heightFeet" name="heightFeet"
                     [(ngModel)]="subjectCriteria.heightFeet" subjectHeightFeetValidator min="3" max="11">
              <label for="heightFeet" class="criteria-item-label">feet</label>
              <input type="number" class="mr-2" #heightInches="ngModel" id="heightInches" name="heightInches"
                     [(ngModel)]="subjectCriteria.heightInches" subjectHeightInchValidator min="0" max="11">
              <label for="heightInches" class="criteria-item-label">inches</label>
              <div [hidden]="heightFeet.valid || heightFeet.pristine" class="alert alert-danger">
                Height must be greater than 0 and less than 12 feet
              </div>
              <div [hidden]="heightInches.valid || heightInches.pristine" class="alert alert-danger">
                Height must be from 0 to 11 inches
              </div>
            </div> <!-- Height -->

            <!-- Weight -->
            <div class="criteria-item">
              <label for="weight" class="criteria-item-label">Weight:</label>
              <input type="number" #weight="ngModel" id="weight" name="weight" maxlength="3"
                     [(ngModel)]="subjectCriteria.weight" subjectWeightValidator min="40" max="999">
              <div [hidden]="weight.valid || weight.pristine" class="alert alert-danger">
                Weight must be greater than 0 and less than 999 lbs
              </div>
            </div> <!-- Weight -->
          </div>

          <div class="criteria-section bg-light"> <!-- Second section -->
            <div class="criteria-category">Activity Level:</div>
            <div class="criteria-item mx-2">
              <div class="mb-2">
                <label for="noActivity" class="">
                  <input type="radio" class="mr-1" id="noActivity" name="activityLevel" value="none" [(ngModel)]="subjectCriteria.activityLevel" required>
                  Sedentary (Engages only in activities of independent living)</label>
              </div>
              <div class="mb-2">
                <label for="lowActivity" class="">
                  <input type="radio" class="mr-1" id="lowActivity" name="activityLevel" value="low" [(ngModel)]="subjectCriteria.activityLevel" required>
                  Lightly Active (light exercise or sports 1-3 days per week)
                </label>
              </div>
              <div class="mb-2">
                <label for="medActivity" class="">
                  <input type="radio" class="mr-1" id="medActivity" name="activityLevel" value="medium" [(ngModel)]="subjectCriteria.activityLevel" required>
                  Moderately Active (Walk 1.5 - 3 miles a day, 3-5 days per week)
                </label>
              </div>
              <div class="mb-2">
                <label for="hiActivity" class="">
                  <input type="radio" class="mr-1" id="hiActivity" name="activityLevel" value="high" [(ngModel)]="subjectCriteria.activityLevel" required>
                  Very Active (Hard exercise or sports 6-7 days per week)</label>
              </div>
              <div>
                <label for="extraActivity" class="">
                  <input type="radio" class="mr-1" id="extraActivity" name="activityLevel" value="extra" [(ngModel)]="subjectCriteria.activityLevel" required>
                  Extra Active (Very hard exercise or sports & physical job, or double training)
                </label>
              </div>
            </div>
          </div>

        </div> <!-- First column -->

        <div class="col-md"> <!-- Second column -->
          <div class="criteria-section bg-light"> <!-- First section -->
            <div class="criteria-category">Medical Diagnosis:</div>
            <div class="criteria-item mx-2">
              <div class="mb-2">
                <label for="regular" class="">
                  <input type="radio" class="mr-1" id="regular" name="kidneyDisease" value="regular" [(ngModel)]="subjectCriteria.medicalDiagnosis.kidney"
                         (click)="onKidneyDiseaseSelected($event)">
                  Chronic Kidney Disease
                </label>
              </div>
              <div class="mb-3">
                <label for="dialysis" class="">
                  <input type="radio" class="mr-1" id="dialysis" name="kidneyDisease" value="dialysis" [(ngModel)]="subjectCriteria.medicalDiagnosis.kidney"
                         (click)="onKidneyDiseaseSelected($event)">
                  Chronic Kidney Disease receiving Hemodialysis
                </label>
              </div>
              <div class="mb-3">
                <label for="coronary" class="">
                  <input type="checkbox" class="mr-1" id="coronary" name="coronary" [(ngModel)]="subjectCriteria.medicalDiagnosis.coronary">
                  Coronary Vascular Disease, Coronary Artery Disease, or Coronary Heart Disease
                </label>
              </div>
              <div class="mb-2">
                <label for="type1" class="">
                  <input type="radio" class="mr-1" id="type1" name="diabetes" value="type1" [(ngModel)]="subjectCriteria.medicalDiagnosis.diabetes"
                         (click)="onDiabetesSelected($event)">
                  Diabetes (Type 1)
                </label>
              </div>
              <div class="mb-3">
                <label for="type2" class="">
                  <input type="radio" class="mr-1" id="type2" name="diabetes" value="type2" [(ngModel)]="subjectCriteria.medicalDiagnosis.diabetes"
                         (click)="onDiabetesSelected($event)">
                  Diabetes (Type 2)
                </label>
              </div>
              <div class="mb-3">
                <label for="heartFailure" class="">
                  <input type="checkbox" class="mr-1" id="heartFailure" name="heartFailure" [(ngModel)]="subjectCriteria.medicalDiagnosis.heartFailure">
                  Heart Failure
                </label>
              </div>
              <div class="mb-3">
                <label for="highCholesterol" class="">
                  <input type="checkbox" class="mr-1" id="highCholesterol" name="highCholesterol" [(ngModel)]="subjectCriteria.medicalDiagnosis.highCholesterol">
                  Hypercholesterolemia (High Cholesterol)
                </label>
              </div>
              <div class="mb-3">
                <label for="hyperlipidemia" class="">
                  <input type="checkbox" class="mr-1" id="hyperlipidemia" name="hyperlipidemia" [(ngModel)]="subjectCriteria.medicalDiagnosis.hyperlipidemia">
                  Hyperlipidemia</label>
              </div>
              <div class="mb-3">
                <label for="hypertension" class="">
                  <input type="checkbox" class="mr-1" id="hypertension" name="hypertension" [(ngModel)]="subjectCriteria.medicalDiagnosis.hypertension">
                  Hypertension (High Blood Pressure)</label>
              </div>
              <div>
                <label for="obesity" class="">
                  <input type="checkbox" class="mr-1" id="obesity" name="obesity" [(ngModel)]="subjectCriteria.medicalDiagnosis.obesity">
                  Obesity
                </label>
              </div>
            </div>
          </div>

          <div class="criteria-section bg-light"> <!-- Second section -->
            <div class="criteria-category">Goals:</div>
            <div class="criteria-item mx-2">
              <label for="lose-weight" class="">
                <input type="checkbox" class="mr-1" id="lose-weight" name="lose-weight" [(ngModel)]="subjectCriteria.loseWeight">
                Lose weight
              </label>
            </div>
          </div>

        </div> <!-- Second column -->
      </div>

      <div class="d-flex px-5 py-3 w-100 bg-white">
        <button type="submit" class="btn btn-primary ml-auto" [disabled]="!subjectForm.form.valid">Next</button>
      </div>
    </div> <!-- Container -->
  </form>
</fieldset>
