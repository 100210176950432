import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SubjectCriteriaComponent } from "../pages/subject-criteria/subject-criteria.component";
import { ResultComponent } from "../pages/result/result.component";
import { AppResolver } from "./app.resolver";

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    component: SubjectCriteriaComponent,
    resolve: {
      pageResolve: AppResolver
    }
  },
  { path: 'result',
    component: ResultComponent,
    resolve: {
      pageResolve: AppResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
