import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-female-sex-modal',
  templateUrl: './female-sex-modal.component.html',
  styleUrls: [
    './female-sex-modal.component.scss'
  ]
})
export class FemaleSexModalComponent implements OnInit {

  public response: boolean | undefined;
  public hasResponded = false;

  constructor(private logger: NGXLogger,
    public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  public onResponded() {
    this.logger.debug('FemaleSexModalComponent: response: ' + this.response);
    this.hasResponded = true;
  }

  public onOK() {
    this.activeModal.close(this.response);
  }

}
