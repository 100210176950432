import { Directive } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[subjectHeightInchValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SubjectHeightInchValidator,
      multi: true
    }
  ]
})
export class SubjectHeightInchValidator implements Validator {

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return (this.validateHeightInch(control.value)? null : {invalid: control});
  }

  private validateHeightInch(value: number) {
    return value >= 0 && value <= 11;
  }

}
