import { Component, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Diabetes, KidneyDisease } from "src/app/constants/subject-criteria.constants";
import { LabelAmountService } from "src/app/service/label-amount.service";

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {

  public warnSweet = false;
  public hasKidneyDisease = false;
  public hasDiabetes = false;

  constructor(private logger: NGXLogger,
              private labelAmountService: LabelAmountService) { }

  ngOnInit(): void {
    this.init();
  }

  private init(): void {
    this.warnSweet = this.labelAmountService.labelAmount.warnSweetDrinks;
    this.hasKidneyDisease = this.labelAmountService.subjectCriteria.medicalDiagnosis.kidney !== KidneyDisease.None;
    this.hasDiabetes = this.labelAmountService.subjectCriteria.medicalDiagnosis.diabetes !== Diabetes.None;
  }

}
