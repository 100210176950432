import { Component, Input, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'app-daily-value-label',
  templateUrl: './daily-value-label.component.html',
  styleUrls: ['./daily-value-label.component.scss']
})
export class DailyValueLabelComponent implements OnInit {

  @Input()
  public isAnnotated = false;

  constructor(private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.logger.info('isAnnotated: ' + this.isAnnotated);
  }

}
