<div class="nutrition-label">

  <!-- Label Heading -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-title">
      <div class="nutrition-label-item-content-title">
        <span class="emphasis color-primary">Your</span>&nbsp;Nutrition Facts
      </div>
    </div>
  </div>

  <!-- Daily amount -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-daily">
      <div class="nutrition-label-item-content-daily">
        Amounts per&nbsp;<span class="emphasis">Day</span>
      </div>
    </div>
  </div>

  <!-- Calories -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-calories">
      <div class="nutrition-label-item-content separator-thick">
        <span class="item-label-calories">Calories</span>
        <span class="label-item-amount-calories">{{labelAmount.calories}}</span>
      </div>
    </div>
  </div>

  <!-- Total Fat -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-main-item">
      <div class="nutrition-label-item-content">
        <span>Total Fat</span>
        <app-label-item-amount [rangeValue]="labelAmount.totalFat"></app-label-item-amount>
      </div>
    </div>
  </div>

  <!-- Saturated Fat -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-sub-item">
      <div class="nutrition-label-sub-item-content">
        <span class="sub-item-label">Saturated Fat</span>
        <span class="label-item-amount label-item-highlight">{{labelAmount.saturatedFat}}g</span>
      </div>
    </div>
  </div>

  <!-- Trans Fat -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-sub-item">
      <div class="nutrition-label-sub-item-content">
        <span class="sub-item-label">Trans Fat</span>
        <span class="item-warning label-item-highlight" *ngIf="labelAmount.warnTransFat">Avoid Trans Fats</span>
      </div>
    </div>
  </div>

  <!-- Cholesterol -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-main-item">
      <div class="nutrition-label-item-content">
        <span>Cholesterol</span>
        <span class="label-item-amount label-item-highlight">{{labelAmount.cholesterol}}mg</span>
      </div>
    </div>
  </div>

  <!-- Sodium -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-main-item">
      <div class="nutrition-label-item-content">
        <span>Sodium</span>
        <span class="label-item-amount label-item-highlight">{{labelAmount.sodium}}mg</span>
      </div>
    </div>
  </div>

  <!-- Carbohydrates -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-main-item">
      <div class="nutrition-label-item-content">
        <span>Total Carbohydrates</span>
        <app-label-item-amount [rangeValue]="labelAmount.totalCarbohydrates"></app-label-item-amount>
      </div>
    </div>
  </div>

  <!-- Daily Fiber -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-sub-item">
      <div class="nutrition-label-sub-item-content">
        <span class="sub-item-label">Daily Fiber</span>
        <app-label-item-amount [rangeValue]="labelAmount.dietaryFiber"></app-label-item-amount>
      </div>
    </div>
  </div>

  <!-- Total Sugars -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-sub-item">
      <div class="nutrition-label-sub-item-content">
        <span class="sub-item-label">Total Sugars</span>
        <span class="label-item-amount"></span>
      </div>
    </div>
  </div>

  <!-- Added Sugars -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-sub-item">
      <div class="nutrition-label-sub-item-content">
        <span class="sub-item-label pl-5">Includes Added Sugars</span>
        <span class="label-item-amount label-item-highlight" *ngIf="!labelAmount.warnAddedSugar">{{labelAmount.addedSugar}}g</span>
        <span class="item-warning" *ngIf="labelAmount.warnAddedSugar">Avoid Added Sugars</span>
      </div>
    </div>
  </div>

  <!-- Protein -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-main-item">
      <div class="nutrition-label-item-content separator-thick">
        <span>Protein</span>
        <app-label-item-amount [rangeValue]="labelAmount.protein"></app-label-item-amount>
      </div>
    </div>
  </div>

  <!-- Vitamin D -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-main-item">
      <div class="nutrition-label-item-content">
        <span class="item-label">Vitamin D</span>
        <span class="label-item-amount label-item-highlight">{{labelAmount.ageBasedAmount.vitaminD}}</span>
      </div>
    </div>
  </div>

  <!-- Calcium -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-main-item">
      <div class="nutrition-label-item-content">
        <span class="item-label">Calcium</span>
        <app-label-item-amount [rangeValue]="labelAmount.ageBasedAmount.calcium"></app-label-item-amount>
      </div>
    </div>
  </div>

  <!-- Iron -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-main-item">
      <div class="nutrition-label-item-content">
        <span class="item-label">Iron</span>
        <span class="label-item-amount label-item-highlight">{{labelAmount.ageBasedAmount.iron}}</span>
      </div>
    </div>
  </div>

  <!-- Potassium -->
  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-main-item">
      <div class="nutrition-label-item-content">
        <span class="item-label">Potassium</span>
        <span class="label-item-amount label-item-highlight">{{labelAmount.ageBasedAmount.potassium}}</span>
      </div>
    </div>
  </div>

  <div class="d-flex row justify-content-center">
    <div class="d-flex col-9 nutrition-label-footer pt-2"></div>
  </div>

</div>
