import { Directive } from '@angular/core';
import { AbstractControl, FormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[subjectHeightFeetValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: SubjectHeightFeetValidator,
      multi: true
    }
  ]
})
export class SubjectHeightFeetValidator implements Validator {

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return (this.validateHeightFeet(control.value)? null : {invalid: control});
  }

  private validateHeightFeet(value: number) {
    return !!value && value > 0 && value <= 11;
  }

}
