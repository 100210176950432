<div class="nutrition-label">

  <!-- Label Heading -->
  <app-label-item-layout [isAnnotated]="isAnnotated" [additionalMainItemClasses]="['nutrition-label-title']" [additionalItemContentClasses]="['nutrition-label-title-content']">
    Nutrition Facts
  </app-label-item-layout>

  <!-- Servings -->
  <app-label-item-layout [isAnnotated]="isAnnotated" [additionalItemContentClasses]="['border-0']">
    <span class="item-label">8 servings per container</span>
  </app-label-item-layout>

  <!-- Serving Size -->
  <app-label-item-layout [isAnnotated]="isAnnotated" [additionalItemContentClasses]="['separator-thickest']">
    <span>Serving size</span>
    <span class="item-percentage" style="width: 8rem">1 cup (60g)</span>
  </app-label-item-layout>

  <!-- Calories -->
  <app-label-item-layout [isAnnotated]="isAnnotated" [additionalItemContentClasses]="['separator-thick']">
    <span class="item-label calories">Calories</span>
    <span class="item-percentage calories">180</span>
  </app-label-item-layout>

  <!-- % Daily Value -->
  <app-label-item-layout [isAnnotated]="isAnnotated" annotation="Using %DV">
    <span></span>
    <span class="item-percentage" style="width: 7rem;" [ngClass]="{'annotated-value': isAnnotated}">% Daily Value*</span>
  </app-label-item-layout>

  <!-- Total Fat -->
  <app-label-item-layout [isAnnotated]="isAnnotated">
    <span>Total Fat</span>&nbsp;<span class="item-amount" *ngIf="!isAnnotated">8g</span>
    <span class="item-percentage" [ngClass]="{'annotated-value': isAnnotated}">10%</span>
  </app-label-item-layout>

  <!-- Saturated Fat -->
  <app-label-item-layout [isAnnotated]="isAnnotated" annotation="Choose 5% or" [additionalAnnotationClasses]="['label-annotation-maximum', 'vertical-shift']">
    <span class="sub-item-label w-50" [ngClass]="{'label-annotation-maximum': isAnnotated}">Saturated Fat <ng-container *ngIf="!isAnnotated">0.5g</ng-container></span>
    <span class="item-percentage" [ngClass]="{'label-annotation-maximum': isAnnotated}">3%</span>
  </app-label-item-layout>

  <!-- Trans Fat -->
  <app-label-item-layout [isAnnotated]="isAnnotated" annotation="less to limit" [additionalAnnotationClasses]="['label-annotation-maximum', 'vertical-shift']">
    <span class="sub-item-label w-50" [ngClass]="{'label-annotation-maximum': isAnnotated}"><em>Trans Fat</em> <ng-container *ngIf="!isAnnotated">Fat 0g</ng-container></span>
    <span class="item-percentage" [ngClass]="{'label-annotation-maximum': isAnnotated}">&nbsp;</span>
  </app-label-item-layout>

  <!-- Cholesterol -->
  <app-label-item-layout [isAnnotated]="isAnnotated" annotation="these Nutrients" [additionalAnnotationClasses]="['label-annotation-maximum', 'vertical-shift']">
    <span class="w-50" [ngClass]="{'label-annotation-maximum': isAnnotated}">Cholesterol <ng-container *ngIf="!isAnnotated">0mg</ng-container></span>
    <span class="item-percentage" [ngClass]="{'label-annotation-maximum': isAnnotated}">0%</span>
  </app-label-item-layout>

  <!-- Sodium -->
  <app-label-item-layout [isAnnotated]="isAnnotated" [additionalAnnotationClasses]="['label-annotation-maximum']">
    <span class="w-50" [ngClass]="{'label-annotation-maximum': isAnnotated}">Sodium <ng-container *ngIf="!isAnnotated">70mg</ng-container></span>
    <span class="item-percentage" [ngClass]="{'label-annotation-maximum': isAnnotated}">4%</span>
  </app-label-item-layout>

  <!-- Carbohydrates -->
  <app-label-item-layout [isAnnotated]="isAnnotated">
    <span>Total Carbohydrates</span>&nbsp;<span class="item-amount" *ngIf="!isAnnotated">64g</span>
    <span class="item-percentage" [ngClass]="{'annotated-value': isAnnotated}">24%</span>
  </app-label-item-layout>

  <!-- Daily Fiber -->
  <app-label-item-layout [isAnnotated]="isAnnotated" annotation="Choose 20%" [additionalAnnotationClasses]="['label-annotation-minimum', 'vertical-shift']">
    <span class="sub-item-label w-50" [ngClass]="{'label-annotation-minimum': isAnnotated}">Daily Fiber <ng-container *ngIf="!isAnnotated">6g</ng-container></span>
    <span class="item-percentage" [ngClass]="{'label-annotation-minimum': isAnnotated}">20%</span>
  </app-label-item-layout>

  <!-- Total Sugars -->
  <app-label-item-layout [isAnnotated]="isAnnotated" annotation="or more" [additionalItemContentClasses]="['border-bottom-0']" [additionalAnnotationClasses]="['label-annotation-minimum', 'vertical-shift']">
    <span class="sub-item-label">Total Sugars <ng-container *ngIf="!isAnnotated">11g</ng-container></span>
    <span class="item-percentage" [ngClass]="{'annotated-value': isAnnotated}">&nbsp;</span>
  </app-label-item-layout>

  <!-- Added Sugars -->
  <app-label-item-layout [isAnnotated]="isAnnotated" annotation="to get enough" [additionalAnnotationClasses]="['label-annotation-minimum', 'vertical-shift']">
    <span class="sub-item-label pl-5">Includes <ng-container *ngIf="!isAnnotated">0g</ng-container> Added Sugars</span>
    <span class="item-percentage" [ngClass]="{'annotated-value': isAnnotated}">0%</span>
    <!--        <span class="item-warning" *ngIf="labelAmount.warnAddedSugar">Avoid Added Sugars</span> -->
  </app-label-item-layout>

  <!-- Protein -->
  <app-label-item-layout [isAnnotated]="isAnnotated" annotation="of these" [additionalItemContentClasses]="['separator-thickest']" [additionalAnnotationClasses]="['label-annotation-minimum', 'vertical-shift']">
    <span>Protein</span>&nbsp;<span class="item-amount" *ngIf="!isAnnotated">11g</span>
    <span class="item-percentage" [ngClass]="{'annotated-value': isAnnotated}">16%</span>
  </app-label-item-layout>

  <!-- Vitamin D -->
  <app-label-item-layout [isAnnotated]="isAnnotated" annotation="Nutrients" [additionalAnnotationClasses]="['label-annotation-minimum', 'vertical-shift']">
    <span class="item-label w-50" [ngClass]="{'label-annotation-minimum': isAnnotated}">Vitamin D <ng-container *ngIf="!isAnnotated">5mcg</ng-container></span>
    <span class="item-percentage" [ngClass]="{'label-annotation-minimum': isAnnotated}">25%</span>
  </app-label-item-layout>

  <!-- Calcium -->
  <app-label-item-layout [isAnnotated]="isAnnotated" [additionalAnnotationClasses]="['label-annotation-minimum']">
    <span class="item-label w-50" [ngClass]="{'label-annotation-minimum': isAnnotated}">Calcium <ng-container *ngIf="!isAnnotated">290mg</ng-container></span>
    <span class="item-percentage" [ngClass]="{'label-annotation-minimum': isAnnotated}">22%</span>
  </app-label-item-layout>

  <!-- Iron -->
  <app-label-item-layout [isAnnotated]="isAnnotated" [additionalAnnotationClasses]="['label-annotation-minimum']">
    <span class="item-label w-50" [ngClass]="{'label-annotation-minimum': isAnnotated}">Iron <ng-container *ngIf="!isAnnotated">5mg</ng-container></span>
    <span class="item-percentage" [ngClass]="{'label-annotation-minimum': isAnnotated}">25%</span>
  </app-label-item-layout>

  <!-- Potassium -->
  <app-label-item-layout [isAnnotated]="isAnnotated" [additionalItemContentClasses]="['separator-thick']" [additionalAnnotationClasses]="['label-annotation-minimum']">
    <span class="item-label w-50" [ngClass]="{'label-annotation-minimum': isAnnotated}">Potassium <ng-container *ngIf="!isAnnotated">690mg</ng-container></span>
    <span class="item-percentage" [ngClass]="{'label-annotation-minimum': isAnnotated}">20%</span>
  </app-label-item-layout>

  <!-- Footer -->
  <app-label-item-layout [isAnnotated]="isAnnotated" [additionalMainItemClasses]="['nutrition-label-footer']" [additionalItemContentClasses]="['nutrition-label-footer-content']">
        * The % Daily Value (DV) tells you how much a nutrient in a serving of food contributes to a daily diet.&nbsp;<span class="text-alert">2000</span> calories a day is used for general nutritional advice.
  </app-label-item-layout>

</div>
