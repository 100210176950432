import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subject-criteria',
  templateUrl: './subject-criteria.component.html',
  styleUrls: [
    './subject-criteria.component.scss',
  ]
})
export class SubjectCriteriaComponent implements OnInit {

    ngOnInit(): void {
    }

}
