import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { LabelAmountService } from "../../service/label-amount.service";

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: [
    './result.component.scss',
    '../../style/btn.scss'
  ]
})
export class ResultComponent implements OnInit {

  @ViewChild('resultContent') resultContent: ElementRef | undefined;

  constructor(private logger: NGXLogger,
              private router: Router,
              private labelAmountService: LabelAmountService) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  public back() {
    this.router.navigate(['/home']);
  }

  public print() {
    window.print();
  }

  /**
   * Save file as PDF
   */
  public save() {
    if (this.resultContent) {

    }
  }
}
