export class LabelAmount {
  public calories = 0;
  public totalFat: AmountRange = {low: 0, high: 0, suffix: ''};
  public saturatedFat: number = 0;
  public cholesterol = 0;
  public sodium = 0;
  public totalCarbohydrates: AmountRange = {low: 0, high: 0, suffix: ''};
  public dietaryFiber: AmountRange = {low: 0, high: 0, suffix: ''};
  public addedSugar: number = 0;
  public protein: AmountRange = {low: 0, high: 0, suffix: ''};
  public ageBasedAmount: AgeBasedAmount = new AgeBasedAmount();
  public warnTransFat = false;
  public warnAddedSugar = false;
  public warnSweetDrinks = false;
}

export class AgeBasedAmount {
  public minAge: number = 0;
  public maxAge: number = 0;
  public vitaminD: string = '';
  public calcium: AmountRange = {low: 0, high: 0, suffix: ''};
  public iron: string = '';
  public potassium: string = '';
}

export class AmountRange {
  public low: number = 0;
  public high: number = 0;
  public suffix = '';
  constructor(low: number, high: number, suffix: string) {
    this.low = low;
    this.high = high;
    this.suffix = suffix;
  }
}

