import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-heading-img',
  templateUrl: './app-heading-img.component.html',
  styleUrls: ['./app-heading-img.component.scss']
})
export class AppHeadingImgComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
