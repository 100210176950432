import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-diet-dash',
  templateUrl: './diet-dash.component.html',
  styleUrls: ['./diet-dash.component.scss']
})
export class DietDashComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
