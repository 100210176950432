<div class="d-flex row justify-content-center">
  <div class="d-flex col-8 nutrition-label-main-item" [ngClass]="additionalMainItemClasses">
    <div class="nutrition-label-item-content" [ngClass]="additionalItemContentClasses">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="d-flex col-3 annotation" [ngClass]="additionalAnnotationClasses" *ngIf="isAnnotated">
    <span class="text-left">{{annotation}}</span>
  </div>
</div>
